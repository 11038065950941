import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';
import './navbar.css';

const Menu = () => (
  <>
    <p><a href="#home">Home</a></p>
    <p><a href="#Overview">Overview</a></p>
    <p><a href="#possibility">FAQ</a></p>
    <p><a href="#Download">Download</a></p>
  </>
)

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className='gpt3__navbar-logo'>
          <p>LapSmart</p>
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        {/* <p>Sign in</p>
        <button type="button">Sign up</button> */}
      </div>
      <div className='gpt3__navbar-menu'>
        {/* desktop menu */}
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {/* mobile menu */}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className='gpt3__navbar-menu_container-links'>
              <Menu />
            </div>
            <div className='gpt3__navbar-menu_container-link-sign'>
              <p>Sign in</p>
              <button type="button">Sign up</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar

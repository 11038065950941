import React from 'react'
import './blog.css';

const Blog = () => {
  return (
    <div>
      Blog
    </div>
  )
}

export default Blog
